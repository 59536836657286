import React from 'react';
import logo from '../logo.svg';
import '../App.css';

function BulletPointBar() {

  return (
      <>
          <section className="position-relative" id="next">
              <div className="container py-9 py-lg-11">
                  <div className="row mb-7 mb-lg-11 justify-content-between align-items-end">
                      <div className="col-lg-10 col-xl-9 mx-auto text-center">
                          <span className="h6 mb-4 d-block aos-init aos-animate" data-aos="fade-up">Over XIVISOFT</span>
                          <h2 className="display-4 mb-4 aos-init aos-animate" data-aos="fade-up">Diensten
                          </h2>
                          <span className="width-7x pt-1 mx-auto bg-gradient-primary d-table aos-init aos-animate"
                                data-aos="fade-down"></span>
                      </div>
                  </div>
                  <div className="row pb-lg-7 justify-content-around">
                      <div className="col-12 col-md-6 col-xl-4 mb-7 mb-xl-0 aos-init aos-animate" data-aos="fade-up"
                           data-aos-delay="100">
                          <div className="text-center">
                              <div
                                  className="mb-5 position-relative width-7x height-7x bg-gradient-primary text-white rounded-circle flex-center overflow-hidden">
                                  <i className="icon-Repeat-2 fs-3 position-relative"></i>
                              </div>
                              <h5 className="mb-3">IT-consultancy</h5>
                              <p className="mb-4">
                                  Wij kunnen u (in-house) helpen bij het opzetten van een IT-strategie, software architectuur,
                                  ontwikkeling en implementatie. Wij zijn gespecialiseerd in Microsoft C# .NET en PHP technologie.
                              </p>
                          </div>
                      </div>

                      <div className="col-12 col-md-6 col-xl-4 mb-7 mb-xl-0 aos-init aos-animate" data-aos="fade-up"
                           data-aos-delay="150">
                          <div className="text-center">
                              <div
                                  className="mb-5 position-relative width-7x height-7x bg-gradient-primary text-white rounded-circle flex-center overflow-hidden">
                                  <i className="icon-Light-Bulb fs-3 position-relative"></i>
                              </div>
                              <h5 className="mb-3">Advies</h5>
                              <p className="mb-4">
                                  Als onafhankelijke partij kunnen wij u helpen bij het maken van de juiste keuzes voor uw
                                    organisatie met betrekking tot IT vraagstukken. Van inkoop tot aan audits.
                              </p>
                          </div>
                      </div>

                      <div className="col-12 col-md-6 col-xl-4 aos-init aos-animate" data-aos="fade-up"
                           data-aos-delay="250">
                          <div className="text-center">
                              <div
                                  className="mb-5 position-relative width-7x height-7x bg-gradient-primary text-white rounded-circle flex-center overflow-hidden">
                                  <i className="icon-Duplicate-Window fs-3 position-relative"></i>
                              </div>
                              <h5 className="mb-3">Productontwikkeling</h5>
                              <p className="mb-4">
                                  Naast het aanbieden van consultancy diensten werken wij ook aan eigen producten en diensten.
                                  Enkele voorbeelden:
                              </p>
                              <ul className="list-unstyled mb-4 text-start">
                                  <li className="mb-3">
                                      <a href={'https://aliasvault.net'} 
                                         target='_blank' 
                                         rel="noopener noreferrer"
                                         className="text-decoration-none text-body hover-text-primary d-inline-block ps-3">
                                          AliasVault - een E2E encrypted wachtwoord en aliasmanager
                                      </a>
                                  </li>
                                  <li>
                                      <a href={'https://spamok.nl'} 
                                         target='_blank'
                                         rel="noopener noreferrer"
                                         className="text-decoration-none text-body hover-text-primary d-inline-block ps-3">
                                          SpamOK - voor het maken van tijdelijke e-mailadressen
                                      </a>
                                  </li>
                              </ul>
                          </div>
                      </div>
                  </div>
              </div>
          </section>
      </>
  );
}

/**
 <a href="#!" className="link-hover-underline text-muted fw-semibold">
 Learn More<i className="bx bx-right-arrow-alt align-middle ms-1 lh-1 fs-5"></i>
 </a>
 */

export default BulletPointBar;
