import React from 'react';
import logo from '../logo.svg';
import '../App.css';

function PointMasterPrivacyPolicy() {

  return (
      <>
          <main className="bg-dark text-white">
              <section className="position-relative">
                  <div className="container">
                      <div className="row pt-4 pt-lg-9 justify-content-center">
                          <div className="col-xl-9">
                              <div className="shadow-lg rounded-4 px-4 px-lg-6 py-5 py-lg-7">
                                  <div className="mb-4 pb-4 border-bottom">
                                      <h2>PointMaster Privacy Policy</h2>
                                      <p><small className="text-muted">Date last change:</small> 2024-02-04</p>
                                  </div>
                                  <p>This Privacy Policy describes our policies and procedures on the collection, use,
                                      and disclosure of your information when you use the PointMaster app and tells you
                                      about your privacy rights and how the law protects you.</p>
                                  <p><strong>Types of Data Collected</strong></p>
                                  <h2>Personal Data</h2>
                                  <p>PointMaster does not collect any personal data. We do not require you to provide us
                                      with any personally identifiable information to use our app.</p>

                                  <h2>Usage Data</h2>
                                  <p>PointMaster does not collect usage data. Our app does not connect to servers, and
                                      we do not track or monitor your interaction with the app.</p>

                                  <h2>Tracking & Cookies Data</h2>
                                  <p>We do not use cookies or similar tracking technologies to monitor our app's
                                      usage.</p>

                                  <p><strong>Use of Your Personal Data</strong></p>
                                  <p>The app does not collect or use any personal data.</p>

                                  <p><strong>Disclosure of Your Personal Data</strong></p>
                                  <p>Since we do not collect any personal data, there is no personal data to
                                      disclose.</p>

                                  <p><strong>Security of Your Personal Data</strong></p>
                                  <p>The security of your personal data is not applicable since we do not collect any
                                      such information.</p>

                                  <p><strong>Children's Privacy</strong></p>
                                  <p>Our app does not knowingly collect personally identifiable information from anyone
                                      under the age of 13. Since we do not collect personal data, this is inherently
                                      upheld.</p>

                                  <p><strong>Changes to This Privacy Policy</strong></p>
                                  <p>We may update our Privacy Policy from time to time. We will notify you of any
                                      changes by posting the new Privacy Policy on this page.</p>
                                  <p>We will let you know via email and/or a prominent notice on our Service, prior to
                                      the change becoming effective and update the "Last updated" date at the top of
                                      this Privacy Policy.</p>
                                  <p>You are advised to review this Privacy Policy periodically for any changes. Changes
                                      to this Privacy Policy are effective when they are posted on this page.</p>

                                  <p><strong>Contact Us</strong></p>
                                  <p>If you have any questions about this Privacy Policy, You can contact us:</p>
                                  <ul>
                                      <li>By email: info@xivisoft.com</li>
                                  </ul>
                              </div>
                          </div>
                      </div>
                  </div>
              </section>
          </main>
      </>
  );
}

export default PointMasterPrivacyPolicy;
