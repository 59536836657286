import React from 'react';
import logo from '../logo.svg';
import '../App.css';

function NavBar() {

  return (
      <>
          <section className="position-relative bg-dark">
              <nav className="navbar navbar-expand-lg navbar-dark">
                  <div className="container position-relative">
                      <a className="navbar-brand" href="/">
                          <img src="/assets/img/xivisoft-logo.png" alt="" style={{ "--width": '300px' } as React.CSSProperties} className={'img-fluid'} />
                      </a>

                      <div className="d-flex align-items-center navbar-no-collapse-items order-lg-last">
                          <button className="navbar-toggler order-last" type="button" data-bs-toggle="collapse"
                                  data-bs-target="#navbar-demo-dark" aria-controls="navbar-demo-dark"
                                  aria-expanded="false" aria-label="Toggle navigation">
                                <span className="navbar-toggler-icon">
                                    <i></i>
                                </span>
                          </button>
                      </div>
                      <div className="collapse navbar-collapse" id="navbar-demo-dark">
                          <ul className="navbar-nav ms-auto">
                              <li className="nav-item">
                                  <a href="#contact" className="nav-link">Contactgegevens</a>
                              </li>
                          </ul>
                      </div>
                  </div>
              </nav>
          </section>
      </>
  );
}

export default NavBar;
