import React from 'react';
import logo from '../logo.svg';
import '../App.css';

function ParticlesHeader() {

    // Component will mount
    React.useEffect(() => {
        window.homepageParticlesEnable();
    }, []);


  return (
      <>
          <section className="position-relative bg-gradient-primary text-white">
              <svg className="position-absolute opacity-75 satrt-0 top-0 w-100 h-100" xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="none" viewBox="0 0 700 700" width="700" height="700" opacity="1">
                  <defs>
                      <linearGradient gradientTransform="rotate(136, 0.5, 0.5)" x1="50%" y1="0%" x2="50%" y2="100%" id="ffflux-gradient">
                          <stop stopColor="#4f4f4f" stopOpacity="1" offset="0%"></stop>
                          <stop stopColor="#ec8025" stopOpacity="1" offset="100%"></stop>
                      </linearGradient>
                      <filter id="ffflux-filter" x="-20%" y="-20%" width="140%" height="140%" filterUnits="objectBoundingBox" primitiveUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                          <feGaussianBlur stdDeviation="0 0" x="0%" y="0%" width="100%" height="100%" in="turbulence" edgeMode="duplicate" result="blur"></feGaussianBlur>
                          <feBlend mode="hard-light" x="0%" y="0%" width="100%" height="100%" in="SourceGraphic" in2="blur" result="blend"></feBlend>
                          <feColorMatrix type="" values="3" x="0%" y="0%" width="100%" height="100%" in="blend" result="colormatrix"></feColorMatrix>
                      </filter>
                  </defs>
                  <rect width="700" height="700" fill="url(#ffflux-gradient)" filter="url(#ffflux-filter)"></rect>
              </svg>
              <div id="particles-js" className="position-absolute start-0 top-0 w-100 h-100"></div>
              <div className="container pt-12 pb-9 position-relative z-index-1">
                  <div className="row pt-lg-8 pb-lg-7 justify-content-center text-center align-items-center">
                      <div className="col-lg-8 col-xl-7 mx-auto text-center">
                          <h1 className="mb-4 display-1">XIVISOFT</h1>
                          <p className="col-lg-12 mx-auto mb-5 lead">
                              IT-consultancy, ontwikkelaar en leverancier van online (maatwerk) oplossingen.
                          </p>
                          <a href="#contact" className="btn btn-outline-white border-2 btn-lg">Neem contact op</a>
                      </div>
                  </div>
              </div>
          </section>
      </>
  );
}

export default ParticlesHeader;
