import React from 'react';
import logo from '../logo.svg';
import '../App.css';

function Footer() {

  return (
      <>
          <footer id="footer" className="bg-dark footer text-white position-relative">
              <div className="pb-5">
                  <div className="container">
                      <div className="row justify-content-md-between align-items-center">
                          <div className="d-flex mb-3 mb-md-0 col-sm-6 col-md-4">
                              <a href="https://www.linkedin.com/in/leendert-de-borst-02a60844/" className="d-inline-block mb-1 me-2 si rounded-pill si-hover-linkedin">
                                  <i className="bx bxl-linkedin fs-5"></i>
                                  <i className="bx bxl-linkedin fs-5"></i>
                              </a>
                              <a href="https://github.com/lanedirt" className="d-inline-block mb-1 me-2 si rounded-pill si-hover-github">
                                  <i className="bx bxl-github fs-5"></i>
                                  <i className="bx bxl-github fs-5"></i>
                              </a>
                          </div>
                          <div className="col-sm-6 col-md-4 text-sm-end">
                              <span className="d-block lh-sm small text-muted">© Copyright 2011-2025. XIVISOFT. <a href={'algemene-voorwaarden'}>Algemene voorwaarden</a>.
                            </span>
                          </div>
                      </div>
                  </div>
              </div>
          </footer>
      </>
  );
}

export default Footer;
