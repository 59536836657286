import React from 'react';
import {
    BrowserRouter,
    Routes, //replaces "Switch" used till v5
    Route,
} from "react-router-dom";
import './App.css';
import NavBar from "./Pages/NavBar";
import Footer from "./Pages/Footer";
import TermsConditions from "./Pages/TermsConditions";
import Home from "./Pages/Home";
import Contact from "./Pages/Contact";
import PointMasterPrivacyPolicy from "./Pages/PointMasterPrivacyPolicy";

function App() {

  return (
      <>
          <main className="bg-dark text-white">
          <BrowserRouter>
              <NavBar />
                  <Routes>
                      <Route path="/" element={<Home />} />
                      <Route path="/algemene-voorwaarden" element={<TermsConditions />} />
                      <Route path="/algemenevoorwaarden" element={<TermsConditions />} />
                      <Route path="/pointmaster/contact" element={<Contact />} />
                      <Route path="/pointmaster/privacy-policy" element={<PointMasterPrivacyPolicy />} />
                  </Routes>
              <Footer />
          </BrowserRouter>
          </main>
      </>

  );
}

export default App;
