import React from 'react';
import '../App.css';
import ParticlesHeader from "./ParticlesHeader";
import BenefitsPhoto from "./BenefitsPhoto";
import BulletPointBar from "./BulletPointBar";
import Contact from "./Contact";

function Home() {
  return (
      <>
          <ParticlesHeader />
          <BenefitsPhoto />
          <BulletPointBar />
          <Contact />
      </>
  );
}

export default Home;
